import { TableCell, TableRow } from './style';
import { useTheme } from '@mui/material/styles';
import { IconsButton } from '../Buttons';
import EditIcon from 'assets/component/EditIcon';
import CustomTooltip from '../Tooltip';
import { COLORS } from 'styles/colors';
import { NotificationListProps } from './type';
import { DeleteIcon } from 'assets/component';
import { USER_PERMISSIONS } from 'app/features/Constant';
import { getUserPermissions } from 'app/features/authService';
import OverflowTip from '../CustomFixedListView/OverflowTip';
import { getFormatedDate } from 'utils/CommonFn';
import { DATE_FORMAT_3 } from 'utils/Constants';
import moment from 'moment-timezone';
import { ERROR_TEXT } from 'utils/Constants/Content';

function NotificationRowItem({
  key,
  rowItem,
  tableName,
  handleChange,
  onEditIconClick,
}: NotificationListProps): any {
  const theme = useTheme();
  const isHasPermission = getUserPermissions(
    USER_PERMISSIONS.ADD_EDIT_DELETE_NOTIFICATION,
  );
  const isNoticationExpired = rowItem.EndDate
    ? moment() > moment(rowItem.EndDate)
    : false;

  const handleClick = (): any => {};
  return (
    <TableRow
      container
      key={key}
      onClick={handleClick}
      alignContent="center"
      theme={theme}
    >
      <TableCell item xs={2}>
        <OverflowTip text={rowItem.Title || '-'}></OverflowTip>
      </TableCell>
      <TableCell item xs={2}>
        <OverflowTip text={rowItem.Message || '-'}></OverflowTip>
      </TableCell>
      <TableCell item xs={1}>
        <OverflowTip text={rowItem.NotificationType || '-'}></OverflowTip>
      </TableCell>
      <TableCell item xs={1}>
        <OverflowTip text={rowItem.Category || '-'}></OverflowTip>
      </TableCell>

      <TableCell item xs={2}>
        <OverflowTip
          text={getFormatedDate(rowItem.StartDate, DATE_FORMAT_3)}
        ></OverflowTip>
      </TableCell>

      <TableCell item xs={2}>
        <OverflowTip
          text={getFormatedDate(rowItem.EndDate, DATE_FORMAT_3)}
        ></OverflowTip>
      </TableCell>

      <TableCell item xs={2}>
        <CustomTooltip
          title={isNoticationExpired ? ERROR_TEXT.NOTIFICATION_EXPIRED : 'Edit'}
        >
          <div>
            <IconsButton
              name="edit"
              children={<EditIcon />}
              style={{
                background: COLORS.iconBgColor,
                borderRadius: 4,
                marginLeft: '5px',
                opacity: !isHasPermission || isNoticationExpired ? 0.6 : 1,
              }}
              OnIconClick={onEditIconClick}
              isDisabled={isNoticationExpired}
            />
          </div>
        </CustomTooltip>
        <CustomTooltip title={'Delete'}>
          <div>
            <IconsButton
              name="delete"
              children={<DeleteIcon />}
              style={{
                background: COLORS.iconBgColor,
                borderRadius: 4,
                marginLeft: '5px',
                opacity: !isHasPermission ? 0.6 : 1,
              }}
              OnIconClick={handleChange}
            />
          </div>
        </CustomTooltip>
      </TableCell>
    </TableRow>
  );
}
export default NotificationRowItem;
