import { Route, Routes } from 'react-router-dom';
import { isEmpty } from 'utils/CommonFn/validators';
import ProtectedRoute from 'app/component/layout/ProtectedRouteLayout';
import { IsMatchRoute } from './constant';
import Pages from './routerConfig';
import { Suspense } from 'react';
import Loader from 'app/component/layout/Loader/Loader';
import { ErrorsBoundary } from 'app/component/layout/ErrorBoundary';

const renderRoute = (route: any, props: any) => {
  return route.map(
    ({ title, path, element, children = [] }: any, index: number) => {
      const routeProps: any = {
        path,
        key: title,
        index: index === 0,
        element:
          isEmpty(element) || IsMatchRoute.includes(title) ? (
            element
          ) : (
            <ProtectedRoute {...props}>{element}</ProtectedRoute>
          ),
      };
      return (
        <Route {...routeProps} errorElement={<ErrorsBoundary />}>
          {children.length > 0 && <Route>{renderRoute(children, props)}</Route>}
        </Route>
      );
    },
  );
};
const Router = (props: any) => {
  const pageRouts = renderRoute(Pages, props);
  return (
    <ErrorsBoundary>
      <Suspense fallback={<Loader size={100} maxHeight={true} />}>
        <Routes>{pageRouts}</Routes>
      </Suspense>
    </ErrorsBoundary>
  );
};

export default Router;
