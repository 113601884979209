import {
  deleteCookies,
  getFromCookies,
  getFromLocalStorage,
  getFromSessionStorage,
  saveOnCookie,
  saveOnLocalStorage,
  saveOnSessionStorage,
} from 'store/localStore';
import { isEmpty } from 'utils/CommonFn/validators';
import { USER_PERMISSIONS } from './Constant';
import { IMPERSONATE_BUTTON_DISABLE } from 'utils/Constants';
import { ROUTE_PATH } from 'app/pages/Router/constant';
import { STORE_KEY } from 'store/Constant';

export const getAccessToken = (type = ''): any => {
  const key = getAuthKey(type);
  const tokenInfo = key?.token_info ?? null;
  if (!isEmpty(tokenInfo)) {
    return {
      type: tokenInfo?.token_type ? tokenInfo?.token_type : null,
      token: tokenInfo?.access_token ? tokenInfo?.access_token : null,
    };
  }
  return { type: '', token: '' };
};
export const getRefreshToken = (): any => {
  const key = getAuthKey();
  if (!isEmpty(key)) {
    return key?.token_info?.refresh_token
      ? key?.token_info?.refresh_token
      : null;
  }
  return null;
};

export const getTokenKey = (): any => {
  const token: any =
    getFromLocalStorage(STORE_KEY.AD_TOKEN) ??
    getFromCookies(STORE_KEY.AD_TOKEN);
  return !isEmpty(token) ? token : null;
};
export const isLoggedIn = (): boolean => {
  const token = getTokenKey();
  return !!token;
};
export const getCompanyId = (): any => {
  if (isLoggedIn()) {
    let companyID: any = !isEmpty(
      getFromSessionStorage(STORE_KEY.SITE_COMPANY_ID),
    )
      ? getFromSessionStorage(STORE_KEY.SITE_COMPANY_ID)
      : getFromSessionStorage(STORE_KEY.COMPANY_ID);
    if (isEmpty(getFromSessionStorage(STORE_KEY.IM_TOKEN))) {
      companyID = !isEmpty(getFromLocalStorage(STORE_KEY.SITE_COMPANY_ID))
        ? getFromLocalStorage(STORE_KEY.SITE_COMPANY_ID)
        : getFromLocalStorage(STORE_KEY.COMPANY_ID);
    }
    if (companyID) {
      return companyID;
    } else {
      return null;
    }
  }
  return null;
};
export const getUserId = (type = ''): any => {
  if (isLoggedIn()) {
    return getUserDetails(type)?.UserId ? getUserDetails(type)?.UserId : null;
  }
  return null;
};
export const getSiteId = (): any => {
  if (isLoggedIn()) {
    let siteID = !isEmpty(getFromSessionStorage(STORE_KEY.SITE_INFO_ID))
      ? getFromSessionStorage(STORE_KEY.SITE_INFO_ID)
      : getFromSessionStorage(STORE_KEY.SITE_ID);
    if (isEmpty(getFromSessionStorage(STORE_KEY.IM_TOKEN))) {
      siteID = !isEmpty(getFromLocalStorage(STORE_KEY.SITE_INFO_ID))
        ? getFromLocalStorage(STORE_KEY.SITE_INFO_ID)
        : getFromLocalStorage(STORE_KEY.SITE_ID);
    }
    if (siteID) {
      return siteID;
    } else {
      return null;
    }
  }
  return null;
};
export const getUserEmail = (): any => {
  if (isLoggedIn()) {
    const userEmail: any = getUserDetails()?.Email;
    if (userEmail) {
      return userEmail;
    } else {
      return null;
    }
  }
  return null;
};
export const getUserDetails = (type = ''): any => {
  if (isLoggedIn()) {
    const { token } = getAccessToken(type);
    const { payload } = !isEmpty(token) && getAuthData(token);
    return payload;
  }
  return null;
};

export const userLogout = (): void => {
  window.localStorage.clear();
  window.sessionStorage.clear();
  deleteCookies();
};

export const updateStorage = (key: string, value: string): void => {
  saveOnCookie(key, value);
  saveOnLocalStorage(key, value);
};
export const getUserPermissions = (permissionName: string): any => {
  if (!isEmpty(permissionName) || !isImpersonateUser()) {
    if (isLoggedIn()) {
      const permissions = getUserDetails()?.permissions ?? [];
      try {
        if (permissions && permissions.length > 0) {
          const filteredStrings = permissions.filter((str: any) =>
            str
              .toLowerCase()
              .replace(/\s/g, '')
              .includes(permissionName.toLowerCase().replace(/\s/g, '')),
          );
          return filteredStrings.length > 0;
        }
      } catch (error) {
        return false;
      }
    }
  }
  return false;
};

export const getUserVideoPermissions = (permissionName: string): any => {
  if (!isEmpty(permissionName) || !isImpersonateUser()) {
    if (isLoggedIn()) {
      const permissions = getUserDetails()?.permissions ?? [];
      try {
        if (permissions && permissions.length > 0) {
          const filteredStrings = permissions.filter(
            (str: any) =>
              str.toLowerCase().replace(/\s/g, '') ===
              permissionName.toLowerCase().replace(/\s/g, ''),
          );
          return filteredStrings.length > 0;
        }
      } catch (error) {
        return false;
      }
    }
  }
  return false;
};
export const checkMenusPermission = (element: any): boolean => {
  switch (element) {
    case `/${ROUTE_PATH.OPEN_SERVICE_REQUEST}`:
      return getUserPermissions(USER_PERMISSIONS.OPEN_SERVICE_REQUEST);
    case `/${ROUTE_PATH.INSIGHTS_ANALYTICS}`:
      return getUserPermissions(USER_PERMISSIONS.INSIGHTS_AND_ANALYTICS);
    case `/${ROUTE_PATH.PARTNER}`:
      return getUserPermissions(USER_PERMISSIONS.PARTNER_PORTALS);
    case `/${ROUTE_PATH.BUSINESS_INTELLIGENCE}`:
      return getUserPermissions(
        USER_PERMISSIONS.PARTNER_PORTALS_BUSINESS_INTELLIGENCE,
      );
    case `/${ROUTE_PATH.PARTNER}/${ROUTE_PATH.MANAGED_NETWORK}`:
      return getUserPermissions(
        USER_PERMISSIONS.PARTNER_PORTALS_NETWORK_AND_VOICE,
      );
    case `/${ROUTE_PATH.PARTNER}/${ROUTE_PATH.ACCESS_ALARM}`:
      return getUserPermissions(
        USER_PERMISSIONS.PARTNER_PORTALS_BUSINESS_SECURITY,
      );
    case `/${ROUTE_PATH.DEVICES}`:
      return getUserPermissions(USER_PERMISSIONS.DEVICES);
    case `/${ROUTE_PATH.MY_OPEN_PROJECTS}`:
      return getUserPermissions(USER_PERMISSIONS.MY_OPEN_PROJECTS);
    case `/${ROUTE_PATH.ADMIN}`:
      return getUserPermissions(USER_PERMISSIONS.ADMINISTRATION);
    case `/${ROUTE_PATH.ADMIN}/${ROUTE_PATH.PERMISSIONS}`:
      return getUserPermissions(USER_PERMISSIONS.ADMINISTRATION_PERMISSIONS);
    case `/${ROUTE_PATH.ADMIN}/${ROUTE_PATH.USERS}`:
      return getUserPermissions(USER_PERMISSIONS.ADMINISTRATION_USERS);
    case `/${ROUTE_PATH.ADMIN}/${ROUTE_PATH.NOTIFICATION}`:
      return getUserPermissions(USER_PERMISSIONS.ADMINISTRATION_NOTIFICATION);
    case `/${ROUTE_PATH.ADMIN}/${ROUTE_PATH.COMPANY_SITE}`:
      return getUserPermissions(
        USER_PERMISSIONS.ADMINISTRATION_COMPANY_AND_SITE,
      );
    case `/${ROUTE_PATH.REPORTS}`:
      return getUserPermissions(USER_PERMISSIONS.REPORT_NAVIGATION);
    case `/${ROUTE_PATH.REPORTS}/${ROUTE_PATH.EXPORT_REPORT}`:
      return getUserPermissions(USER_PERMISSIONS.REPORT_NAVIGATION);
    case `/${ROUTE_PATH.REPORTS}/${ROUTE_PATH.SECURITY_MANAGER_REPORT}/${ROUTE_PATH.SM_ALL_ACTIVITY_REPORT}`:
      return getUserPermissions(USER_PERMISSIONS.SM_ALL_ACTIVITY);
    case `/${ROUTE_PATH.REPORTS}/${ROUTE_PATH.SECURITY_MANAGER_REPORT}/${ROUTE_PATH.SM_OPEN_CLOSE_REPORT}`:
      return getUserPermissions(USER_PERMISSIONS.SM_OPEN_CLOSE_ACTIVITY);
    case `/${ROUTE_PATH.REPORTS}/${ROUTE_PATH.SECURITY_MANAGER_REPORT}/${ROUTE_PATH.SM_USER_CONTACTS}`:
      return getUserPermissions(USER_PERMISSIONS.USERS_AND_CONTACTS);
    case `/${ROUTE_PATH.IMPERSONATE_USER}`:
      return getUserPermissions(USER_PERMISSIONS.USER_IMPERSONATE);
    case ROUTE_PATH.ONLINE_PAYMENTS:
      return getUserPermissions(USER_PERMISSIONS.PAYMENT_PORTAL);
    default:
      if (element.includes(ROUTE_PATH.SECURITY_MANAGER_LANDING)) {
        return getUserPermissions(USER_PERMISSIONS.SECURITY_MANAGEMENT);
      } else if (element.includes('siteGroupRealignment')) {
        return getUserPermissions(USER_PERMISSIONS.BULK_UPLOAD_SITE_GROUP);
      } else if (element.includes('BulkUpload')) {
        return getUserPermissions(USER_PERMISSIONS.BULK_UPLOAD_USER);
      }
      return true;
  }
};
export const fetchMasterCompanyId = () => {
  const companyID: any = !isEmpty(getFromSessionStorage(STORE_KEY.COMPANY_ID))
    ? getFromSessionStorage(STORE_KEY.COMPANY_ID)
    : isEmpty(getFromSessionStorage(STORE_KEY.IM_TOKEN))
    ? getFromLocalStorage(STORE_KEY.COMPANY_ID)
    : null;
  return companyID;
};
export const fetchMasterSiteId = () => {
  return !isEmpty(getFromSessionStorage(STORE_KEY.SITE_ID))
    ? getFromSessionStorage(STORE_KEY.SITE_ID)
    : isEmpty(getFromSessionStorage(STORE_KEY.IM_TOKEN))
    ? getFromLocalStorage(STORE_KEY.SITE_ID)
    : null;
};
export const isImpersonateUser = (actionName?: any) => {
  if (isLoggedIn()) {
    if (actionName || actionName === '') {
      const status = !!getFromSessionStorage(STORE_KEY.IM_TOKEN);
      return IMPERSONATE_BUTTON_DISABLE.includes(actionName) && status;
    }
    return !!getFromSessionStorage(STORE_KEY.IM_TOKEN);
  }
  return false;
};

export const persistSiteInfoId = (siteId: any) => {
  isImpersonateUser()
    ? saveOnSessionStorage(STORE_KEY.SITE_INFO_ID, siteId)
    : saveOnLocalStorage(STORE_KEY.SITE_INFO_ID, siteId);
};

/* Setup impersonate user function */
export const setupIMUser = (value: any) => {
  const tokenInfo = value?.tokens?.token_info;
  const tokenStr: any = JSON.stringify(value?.tokens) ?? {};
  const expiry: any = tokenInfo?.expires_in ? tokenInfo?.expires_in * 1000 : 0;
  saveOnLocalStorage(STORE_KEY.IMUSER_ID, '');
  saveOnSessionStorage(STORE_KEY.IM_TOKEN, tokenStr);
  saveOnSessionStorage(STORE_KEY.SESSION_EXPIRED_IN, expiry);
};

export const clearImpersonateSession = () => {
  window.sessionStorage.clear();
  window.opener = null;
  window.open('', '_self');
  window.close();
};
export const IsUserInternal = (): boolean => {
  try {
    if (getUserDetails()) {
      return getUserDetails()?.IsInternal ?? false;
    }
    return false;
  } catch (error) {
    return false;
  }
};
export const getAuthData = (key: any) => {
  try {
    if (!isEmpty(key)) return JSON.parse(atob(key.split('.')[1]));
  } catch (error) {
    console.log(error);
  }
};
export const getAuthKey = (tokenType?: any): any => {
  const imToken = getFromSessionStorage(STORE_KEY.IM_TOKEN);
  const userToken = getFromLocalStorage(STORE_KEY.USER_TOKEN);
  if (!isEmpty(tokenType)) {
    if (tokenType === STORE_KEY.USER_TOKEN) {
      return userToken ? JSON.parse(userToken) : null;
    } else if (tokenType === STORE_KEY.IM_TOKEN) {
      return imToken ? JSON.parse(imToken) : null;
    }
  } else {
    const token: any = !isEmpty(imToken) ? imToken : userToken;
    if (!isEmpty(token)) {
      return JSON.parse(token);
    }
  }
  return null;
};
